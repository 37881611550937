.podcast-player .replay-fullscreen-button,
.podcast-player .replay-pip-button,
.podcast-player .replay-selector-toggle,
.podcast-player .replay-volume,
.podcast-player .replay-volume-disabled,
.podcast-player .replay-video-streamer {
  display: none !important;
}

/* .podcast-player .replay-video-streamer {
  width: 100vw !important;
  height: 100vh !important;
} */

.podcast-player .replay,
.podcast-player .replay-controls-bar,
.podcast-player .replay-selector-items {
  background: none !important;
}

.podcast-player .replay-button,
.podcast-player .replay-play-pause-button {
  color: white !important;
}

.podcast-player .replay-controls-bar {
  width: 80% !important;
  margin-left: 10% !important;
  position: relative !important;
}

.podcast-player .replay-time-display-position,
.podcast-player .replay-time-display-duration {
  color: white !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.podcast-player .replay-time-display {
  flex-direction: row !important;
  width: 100% !important;
  justify-content: space-between !important;
}

.podcast-player .replay-time-display-position {
  margin-top: 4px !important;
  margin-left: -10% !important;
}

.podcast-player .replay-time-display-duration {
  margin-top: -1px !important;
  margin-right: -20% !important;
}

.replay-selector-items {
  display: none;
}

.podcast-player .replay-is-paused,
.podcast-player .replay-controls-bar,
.podcast-player .replay-is-user-inactive,
.podcast-player .replay-controls-bar {
  visibility: visible !important;
  opacity: 1 !important;
}

.podcast-player .replay-is-user-inactive,
.podcast-player .replay-controls-bar {
  visibility: visible !important;
  opacity: 1 !important;
}

.podcast-player .replay-narrow,
.podcast-player .replay-controls-bar {
  height: 80px !important;
}

.podcast-player .replay-play-pause-button {
  position: absolute !important;
  left: 35% !important;
}

.podcast-player .replay-button {
  position: absolute !important;
  left: 50% !important;
}

.podcast-player .replay-buffering-indicator {
  /* display: none !important; */
  color: white !important;
  margin-top: 18% !important;
  /* margin-left: -5% !important; */
}

.podcast-player .replay-slider-track {
  background: grey !important;
}


